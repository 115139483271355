<template>
  <div>
    <div>{{ barcode }}</div>
    <button @click="onDecode('123456')">FAKE BARCODE</button>
    <div>
      <qrcode-stream @decode="onDecode"></qrcode-stream>
    </div>
  </div>
</template>

<script>
//import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

export default {
  name: "BarcodeReader",
  /*components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },*/
  data: function() {
    return {
      barcode: "",
    };
  },
  methods: {
    onDecode: function(decodedString) {
      console.debug(decodedString);
      this.barcode = decodedString;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
