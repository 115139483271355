<template>
  <div id="app">
    <BarcodeReader />
  </div>
</template>

<script>
import BarcodeReader from "./components/BarcodeReader.vue";

export default {
  name: "App",
  components: {
    BarcodeReader,
  },
};
</script>

<style></style>
